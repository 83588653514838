











































































import { Component, Mixins } from 'vue-property-decorator'
import axios from 'axios'

import NotificationMixin from '@/mixins/notificationMixin'

@Component
export default class UsersRolesShow extends Mixins(NotificationMixin) {
  createModalIsOpened = false
  deleteModalIsOpened = false
  permissionsDone = true
  nameDone = true
  permissionNotification = false
  nameNotification = false
  role: Record<string, any> = {}

  form: Record<string, any> = {
    name: '',
    permissions: [],
    errors: {
      name: ''
    }
  }

  permissions: any = {}
  recursos = [
    {
      nome: 'Serviços',
      permissoes: [
        {
          nome: 'Cadastrar',
          checked: true
        },
        {
          nome: 'Ler',
          checked: true
        },
        {
          nome: 'Editar',
          checked: true
        },
        {
          nome: 'Remover',
          checked: true
        }
      ]
    },
    {
      nome: 'Provas',
      permissoes: [
        {
          nome: 'Cadastrar',
          checked: true
        },
        {
          nome: 'Ler',
          checked: true
        },
        {
          nome: 'Editar',
          checked: true
        },
        {
          nome: 'Remover',
          checked: true
        }
      ]
    },
    {
      nome: 'Questões',
      permissoes: [
        {
          nome: 'Cadastrar',
          checked: true
        },
        {
          nome: 'Ler',
          checked: true
        },
        {
          nome: 'Editar',
          checked: true
        },
        {
          nome: 'Remover',
          checked: true
        }
      ]
    },
    {
      nome: 'Biblioteca',
      permissoes: [
        {
          nome: 'Cadastrar',
          checked: true
        },
        {
          nome: 'Ler',
          checked: true
        },
        {
          nome: 'Editar',
          checked: true
        },
        {
          nome: 'Remover',
          checked: true
        }
      ]
    },
    {
      nome: 'Usuários',
      permissoes: [
        {
          nome: 'Cadastrar',
          checked: true
        },
        {
          nome: 'Ler',
          checked: true
        },
        {
          nome: 'Editar',
          checked: true
        },
        {
          nome: 'Remover',
          checked: true
        },
        {
          nome: 'Auditar',
          checked: true
        }
      ]
    }
  ]

  get permissionsLoop () {
    return Object.entries(this.permissions)
  }

  created () {
    const permissions: any = []
    axios.get('permissions/all')
      .then(response => {
        response.data.data.forEach((permission: Record<string, any>) => {
          const [action, name] = permission.name.split(':')

          if (!permissions[name]) {
            permissions[name] = []
          }
          permissions[name].push({ id: permission.id, name: action })
        })
        this.permissions = Object.entries(permissions)
      })

    axios.get(`roles/${this.$route.params.id}`)
      .then(response => {
        this.role = response.data.data
        this.form.name = this.role.name

        this.role.permissions.forEach((permission: Record<string, any>) => {
          this.form.permissions.push(permission.id)
        })
      })
  }

  submitPermissionsForm () {
    this.permissionsDone = false
    this.nameNotification = false
    this.permissionNotification = false

    const permissionsForm = {
      permissions: this.form.permissions
    }

    axios.put(`role/${this.$route.params.id}/permissions`, permissionsForm)
      .then(() => {
        this.setNotification('success', 'Permissões editadas com sucesso.')
      })
      .catch(() => {
        this.setNotification('error', 'Um erro ocorreu ao editar as permissões.')
      })
      .finally(() => { this.permissionsDone = true; this.permissionNotification = true })
  }

  submitNameForm () {
    this.nameDone = false
    this.nameNotification = false
    this.permissionNotification = false
    const nameForm = {
      name: this.role.name,
      newName: this.form.name
    }

    axios.put('roles/update', nameForm)
      .then(() => {
        this.role.name = nameForm.newName
        this.setNotification('success', 'Nome do papel editado com sucesso.')
      })
      .catch((err) => {
        const response = err.response.data
        const errors = response.errors
        if (errors) {
          this.setNotification('error', response.error)
        }
      })
      .finally(() => { this.nameDone = true; this.nameNotification = true })
  }
}
